import React from "react";
import {LinkSharp, WhatsApp} from "@material-ui/icons";
import {Instagram, Phone, PhoneCall, Share2} from "react-feather";
import {Col, NavLink, Row} from "reactstrap";
import logo from "../../../assets/img/logo/logo.webp";
import Technical from "../../../assets/img/logo/Technical professional.png";

class Footer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            whatsapp: false,
            enamad: true,
        }
    }

    support = () => {
        this.setState({
            whatsapp: !this.state.whatsapp,
        })
    }

    render() {
        return (
            <>
                <div className="web-footer">
                    <div className="bg-black-footer">
                        <Row className="p-1 w-100">
                            <Col sm={12} md={4}>
                                <div className="box-for-footer">
                                    <div className="about-us-box d-flex">
                                        <PhoneCall/>
                                        {/*<LocationCity/>*/}
                                        <h3> ارتباط با ما</h3>
                                    </div>
                                    <div className="phones">
                                        <a className="mt-1" href="tel:+989371901643">
                                            <Phone size={20}/>
                                            09371901643
                                        </a>
                                    </div>
                                    <div className="phones">
                                        <a className="mt-1" href="tel:+982634318863">
                                            <Phone size={20}/>
                                            02634318863
                                        </a>
                                    </div>
                                    <p className="mt-1 font-medium-1">کرج-گوهردشت-بلوار انقلاب-نبش هفدهم غربی-طبقه
                                        فوقانی ایران
                                        رادیاتور
                                    </p>
                                    <div className="social-box d-flex justify-content-center">
                                        <NavLink href="https://www.instagram.com/nedakhosroshahibonab/"
                                                 className="my-auto" target="_blank" rel="noopener noreferrer">
                                            <Instagram size={28} className="mr-1 my-auto"/>
                                        </NavLink>
                                        <NavLink
                                            href="https://api.whatsapp.com/send/?phone=989371901643&text&app_absent=0"
                                            className="my-auto"
                                            target="_blank" rel="noopener noreferrer">
                                            <WhatsApp className="mr-1 my-auto"/>
                                        </NavLink>
                                    </div>
                                </div>

                            </Col>
                            <Col sm={12} md={4}>
                                <div className="box-for-footer">
                                    <div className="about-us-box d-flex">
                                        <LinkSharp/>
                                        <h3>
                                            لینک های سریع
                                        </h3>
                                    </div>
                                    <Row className="social-box">
                                        <Col sm={12} md={6}>
                                            <a href="/articles">مقالات</a>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <a href="/gallery">گالری</a>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <a href="https://behdasht.gov.ir/" target="_blank" rel="noopener noreferrer">وزارت بهداشت</a>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <a href="https://www.fda.gov.ir/" target="_blank" rel="noopener noreferrer">سازمان غذا و دارو</a>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <a href="https://irantvto.ir/" target="_blank" rel="noopener noreferrer">سازمان فنی حرفه ای</a>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <a href="/courses/1402-1-31-آشپزی">دوره آشپزی</a>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <a href="/courses/1402-2-4-قناد">دوره قناد</a>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <a href="/courses/1402-2-4-کباب-زنی-و-تخته-کاری">کباب زنی و تخته کاری</a>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col sm={12} md={4}>
                                <div className="box-for-footer">
                                    <div className="about-us-box d-flex">
                                        <Share2/>
                                        <h3>
                                            اعتماد
                                        </h3>
                                    </div>
                                    <div className="m-auto flex-row d-flex pt-1 justify-content-around align-items-center logo-continer">
                                        <div className="m-auto logos">
                                            <div>
                                                <div className="logo">
                                                    <a referrerPolicy="origin" target="_blank" rel="noopener noreferrer"
                                                       href="https://trustseal.enamad.ir/?id=337703&Code=aimBcfHI95pEUbuilCzX">
                                                        <img
                                                            referrerPolicy="origin"
                                                            src="https://trustseal.enamad.ir/logo.aspx?id=337703&Code=aimBcfHI95pEUbuilCzX"
                                                            alt="اینماد" title="اینماد" style={{cursor: "pointer"}}
                                                            id="aimBcfHI95pEUbuilCzX"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="logo">
                                                    <img src={logo} alt="صنایع غذایی ندا" title="صنایع غذایی ندا"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="logo">
                                                <img src={Technical} alt="سازمان فنی حرفه ای" title="سازمان فنی حرفه ای"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {/*<Col sm={12} md={3}>
                            <div className="address-box d-flex">
                                <h3> اعتبار </h3>
                            </div>
                            <div className="d-flex justify-content-center">
                                {this.state.enamad ?
                                    <div>
                                        <a referrerPolicy="origin" target="_blank"
                                           href="https://trustseal.enamad.ir/?id=318214&amp;Code=sLuBDa2yr3l6AoafaRdq">
                                            <img
                                                referrerPolicy="origin"
                                                id="sLuBDa2yr3l6AoafaRdq"
                                                src="https://Trustseal.eNamad.ir/logo.aspx?id=318214&amp;Code=sLuBDa2yr3l6AoafaRdq"
                                                alt="" style={{cursor: "pointer"}}
                                            />
                                        </a>
                                    </div> : null}
                                <div>
                                    <a target="_blank"
                                       href="https://irantvto.ir/">
                                        <img
                                            src={fanni}
                                            alt="" style={{cursor: "pointer", width: "100px"}}

                                        />
                                    </a>
                                </div>
                            </div>
                        </Col>*/}
                        </Row>
                    </div>
                    <div className="copyright p-1 w-100 m-auto">
                        <p className="m-0">
                            کلیه حق و حقوق وبسایت برای آموزشگاه صنایع غذایی ندا محفوظ است
                        </p>
                        <a href="https://barantm.ir" target='_blank' rel='noopener' className="my-auto">
                            <p className="m-0">
                                طراحی و تولید توسط شرکت برنامه نویسی باران
                            </p>
                        </a>

                    </div>
                </div>

            </>
        );
    }
}

export default Footer;
